<template>
    <v-container>
            <v-form ref="offerform">
                <v-text-field v-model="offer_form.offer_price" label="Offer price" ref="price"
                            :rules="[rules.required]" outlined name="price"></v-text-field>
                <v-select v-model="offer_form.payment_plan" :items="pay_plan" label="Payment Option" outlined
                        ref="payment_plan" :rules="[rules.required]"></v-select>

                <div class="offer_radios">
                    <span v-for="(r,i) in [1,2,3,4,5,'6+']" :key="i">
                        <input type="radio" name="bedroom"   :value="r" :id="'bed'+ i" :rules="[rules.required]"  />
                        <label class="radio text-center" :for="'bed'+i" style="width: 40px;height: 40px">
                            <span >{{r}}</span>
                        </label>
                    </span>
                </div>

                <v-row justify="center" style="padding:0 20px">
                    <v-btn color="green darken-1" text @click="closeOfferModal()">Cancel</v-btn>
                    <v-btn type="submit" :loading="apiloading" :disabled="apiloading" color="secondary" width="120"
                        @click.prevent="sendOffer()"><span style="color:#fff">Send</span></v-btn>
                </v-row>
    

        </v-form>
    </v-container>
</template>
<script>
import { loadingMixin } from '../../mixins/Loading';
export default {
       inject:["Bus"],
        mixins:[loadingMixin],
    data() {
        return {
            rules: {
                required: value => !!value || "Required.",
                    phone:value=>{
                const regre = /[0-9]|\./
                    return regre.test(value) || "Invalid number";
                },
            },
            pay_plan:['Outright','Mortgage'],
             offer_form:{
              offer_price:'',
              payment_plan:'',
              agentid: this.$store.state.user_module.user.id,
              propertyid:this.$store.state.property_module.property.id,

            },
        }
    },
}
</script>
<style scoped>
        .offer_radios{
        margin-top:5px;
        display: flex;
        flex-direction:row;
        width:100%;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
    }
    .offer_radios input[type=radio]{
        display: none;
    }
    .offer_radios label{
        /*border-radius: 8px;*/
        text-align: center;
        /*padding:10px;*/
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        padding:5px 10px;
        /*margin:5px 10px;*/
        font-size: 12px;
        background-color: #fff;
        border: 1px solid #000;
        border-radius: 50%;
    }
    .offer_radios label span{
        padding:5px;
        color:rgba(74, 74, 74, 0.69);
        /*background-color: #379392;*/
    }
    .offer_radios input[type=radio]:checked + .radio{
        /*background-color:  rgba(0, 119, 243, 0.05);*/
        background-color: #379392;
        /*color:#0077F3;*/
        color: #fff;
    }
    .offer_radios input[type=radio]:checked + .radio span{
        /*background-color:  rgba(0, 119, 243, 0.05);*/
        background-color: #379392;
        /*color:#0077F3;*/
        color: #fff;
    }
</style>